@import (reference) './antd-theme.less';

/**
 * Seekube (UiKit)
 */

@seekube-primary-color: @primary-color;
@seekube-secondary-color: @info-color;
@seekube-black-color: @text-color;
@seekube-active-color: #2EA3EB;
@seekube-success-color: @success-color;
@seekube-warning-color: @warning-color;
@seekube-error-color: @error-color;
@seekube-light-grey-color: #C3CAD9;
@seekube-light-grey-color2: #B2B9C6;
@seekube-light-border-color: #E6EAF2;
@seekube-grey-light-color: #C4C4C4;
@seekube-grey-color: #949AA6;
@seekube-default-color: #A5ABB9;
@seekube-grey-dark-color: #848B9C;
@seekube-white-grey-color: #F4F5F7;
@seekube-light-blue-color: #96D1F2;
@seekube-disabled-color: @btn-disable-bg;

@seekube-dark-grey-color: #808389;
@seekube-calendar-border-color-primary: rgba(206, 212, 221, 1);
@seekube-calendar-border-color-secondary: rgba(206, 212, 221, 0.5);

@white: #FFF;
@black: #000;

/*
 * New colors design system
 * Migration less files to makeStyles (JSS). Remove this when migration is completed
 */

// Neutral
@seekube-neutral-100: #F4F6FA;
@seekube-neutral-200: #D3DBE8;
@seekube-neutral-300: #A7B6D2;
@seekube-neutral-400: #667EB0;
@seekube-neutral-500: #3C4E73;
@seekube-neutral-600: #30405B;
@seekube-neutral-700: #27334B;
@seekube-neutral-800: #151C28;
@seekube-neutral-900: #090D12;

// Primary
@seekube-primary-100: #F5FBFE;
@seekube-primary-200: #D6EDFB;
@seekube-primary-300: #ADDCF7;
@seekube-primary-400: #70C2F1;
@seekube-primary-500: #33A8EB;
@seekube-primary-600: #158BD0;
@seekube-primary-700: #1171A9;
@seekube-primary-800: #093D6B;
@seekube-primary-900: #041A27;

// Grey
@seekube-grey-100: #F9F9F9;
@seekube-grey-200: #E6E6E7;
@seekube-grey-300: #CCCDCF;
@seekube-grey-400: #A6A8AC;
@seekube-grey-500: #818389;
@seekube-grey-600: #67696E;
@seekube-grey-700: #53555A;
@seekube-grey-800: #2D2E30;
@seekube-grey-900: #131415;

// Success
@seekube-success-100: #F7FCF8;
@seekube-success-200: #DFF2E4;
@seekube-success-300: #BFE5C9;
@seekube-success-400: #8FD1A0;
@seekube-success-500: #61BE79;
@seekube-success-600: #42A25A;
@seekube-success-700: #3E7B4E;
@seekube-success-800: #1D4727;
@seekube-success-900: #0C1E11;

// Warning
@seekube-warning-100: #FFF9F3;
@seekube-warning-200: #FFE8D0;
@seekube-warning-300: #FFD2A1;
@seekube-warning-400: #FFB05B;
@seekube-warning-500: #FF8B13;
@seekube-warning-600: #DC7200;
@seekube-warning-700: #B35C00;
@seekube-warning-800: #603200;
@seekube-warning-900: #291500;

// Error
@seekube-error-100: #FEF5F6;
@seekube-error-200: #F9D7DB;
@seekube-error-300: #F3AFB7;
@seekube-error-400: #EA7381;
@seekube-error-500: #E1364A;
@seekube-error-600: #C31D31;
@seekube-error-700: #9F1827;
@seekube-error-800: #550D15;
@seekube-error-900: #250509;

// Info
@seekube-info-100: #F5F8FC;
@seekube-info-200: #D6E1F5;
@seekube-info-300: #ADC4EB;
@seekube-info-400: #7097DB;
@seekube-info-500: #336ACA;
@seekube-info-600: #2956A3;
@seekube-info-700: #214685;
@seekube-info-800: #122547;
@seekube-info-900: #08101F;

// Visited
@seekube-visited-100: #F8F2FF;
@seekube-visited-200: #E1CCFD;
@seekube-visited-300: #C399FB;
@seekube-visited-400: #974CF9;
@seekube-visited-500: #6B09EC;
@seekube-visited-600: #5607BD;
@seekube-visited-700: #46069A;
@seekube-visited-800: #260353;
@seekube-visited-900: #100123;

// Apple
@seekube-apple-100: #F5FCF7;
@seekube-apple-500: #42B85C;
@seekube-apple-700: #2B773C;

// Blue
@seekube-blue-100: #F4FBFF;
@seekube-blue-200: #D1F0FF;
@seekube-blue-500: #1AB6FF;
@seekube-blue-600: #0099E0;
@seekube-blue-700: #007DB6;

// pink
@seekube-pink-100: #FEF5F9;
@seekube-pink-500: #E83086;
@seekube-pink-700: #A41256;

// chocolate
@seekube-chocolate-100: #FCF9F7;
@seekube-chocolate-200: #F1E6DF;
@seekube-chocolate-500: #BA805E;
@seekube-chocolate-600: #9D6443;
@seekube-chocolate-700: #805137;

// cherry
@seekube-cherry-100: #FFF4F6;
@seekube-cherry-500: #FC1D51;
@seekube-cherry-700: #B4032C;

// orange
@seekube-orange-100: #FFF6F4;
@seekube-orange-500: #FF4F1A;
@seekube-orange-700: #B62B00;

// orchid
@seekube-orchid-100: #FAF5FE;
@seekube-orchid-200: #ECD6FA;
@seekube-orchid-500: #A230E8;
@seekube-orchid-600: #8516CA;
@seekube-orchid-700: #6C12A4;

// royalBlue
@seekube-royalBlue-100: #F1F7FF;
@seekube-royalBlue-200: #C5DDFE;
@seekube-royalBlue-500: #025CD9;
@seekube-royalBlue-600: #0249AE;
@seekube-royalBlue-700: #013C8D;

// teal
@seekube-teal-100: #F5FEFA;
@seekube-teal-500: #30E8A5;
@seekube-teal-600: #16CA88;
@seekube-teal-700: #12A46F;

// gold
@seekube-gold-100: #FCFAF6;
@seekube-gold-500: #CAA14E;
@seekube-gold-700: #8C6C2A;

// yellow
@seekube-yellow-100: #FFFCF5;
@seekube-yellow-500: #FFC835;
@seekube-yellow-700: #C79100;

// Fonts
// @seekube-primary-font: AvenirNext, 'Helvetica Neue', sans-serif;
@seekube-primary-font: Lato, sans-serif;
@seekube-secondary-font: @seekube-primary-font;
@font-family: @seekube-primary-font;

// Shadows
@seekube-shadow-0: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
@seekube-shadow-1: 0 2px 4px 0 rgba(37, 45, 71, 0.1);
@seekube-shadow-2: 0 4px 8px 0 rgba(37, 45, 71, 0.1);
@seekube-shadow-3: 0 8px 16px 0 rgba(37, 45, 71, 0.1);
@seekube-shadow-4: 0 16px 24px 0 rgba(37, 45, 71, 0.1);
@seekube-shadow-5: 0 24px 32px 0 rgba(37, 45, 71, 0.1);
@seekube-shadow-6: 0 10px 32px rgba(0, 0, 0, 0.05);
@seekube-shadow-7: 0 0 8px rgba(37, 45, 71, 0.1);

// Other
@transition-duration: 0.5s;
@bold: 700;
@semi-bold: 600;
@light: 400;
@page-title: page-title;
@page-p: page-p;

.@{page-title} {
  font-size: 26px;
  color: @heading-color;
  font-weight: @bold;
}

.@{page-p} {
  font-weight: 500;
  font-size: @font-size-lg;
  color: @seekube-default-color;
}

@seekube-container-width: 950px;
@seekube-form-width: 740px;
@seekube-container-width-large: 1140px;
@seekube-scene-header-height: 40px;
@seekube-primary-gradient: linear-gradient(97.13deg, #59C2FF 0%, #1270E3 100%);
@seekube-header-height: 69px;

@mainTitleMarginBottom: 24px;

@checkboxListWidth: @checkbox-size + 10px;

@slidingPanelWidth: 900px;

@common-modal-signup-padding: 0 90px 0;

// Media
@seekube-media-mobile: ~'screen and (max-width: 768px)';
@seekube-media-not-mobile: ~'screen and (min-width: 769px)';
@seekube-media-tablet: ~'screen and (max-width: 1239px)';
@seekube-media-desktop: ~'screen and (min-width: 1240px)';

// buttons
@btn-danger-color: @seekube-error-color;
@ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
